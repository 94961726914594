import { IDictionary } from "../models";

export function objectToSearchParams(object: IDictionary<any>) {
  const searchParams = new URLSearchParams();
  Object.keys(object).forEach((key) => searchParams.set(key, object[key]));
  return searchParams;
}

export function objectToFormData(object: IDictionary<any>) {
  const formData = new FormData();
  Object.keys(object).forEach((key) => formData.set(key, object[key]));
  return formData;
}

export function getParameterByName(name, url?) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  let regexS = "[\\?&]" + name + "=([^&#]*)";
  let regex = new RegExp(regexS);
  let results = regex.exec(url);
  return results == null ? null : results[1];
}

export const generateRouteURL = (defaultRouteName) => {
  return `${window.location.origin}/${defaultRouteName}`;
};

export const getRouteFromURL = () => {
  const segments = window.location.href.split("/");
  return segments[segments.length - 1];
};
