import { combineReducers } from "redux";
import { reducer as toastrReducer } from "react-redux-toastr";
import app from "./app";
import { connectRouter } from "connected-react-router";
import teilPensionState, { TeilPensionState } from "./teilpension";
import graphEngineState, { GraphEngineState } from "./graphEngine";
import standaloneStartupState, {
  StandaloneStartupState,
} from "./standaloneStartup";
import { AppState, GlobalSettingsState } from "./types";
import globalSettingsState from "./globalSettings";

export interface RootState {
  app: AppState;
  teilPensionState: TeilPensionState;
  graphEngineState: GraphEngineState;
  standaloneStartupState: StandaloneStartupState;
  globalSettingsState: GlobalSettingsState;
}

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    app,
    teilPensionState,
    graphEngineState,
    standaloneStartupState,
    globalSettingsState,
    toastr: toastrReducer,
  });
