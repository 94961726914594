import { getParameterByName } from "../utils/toSearchParams";
import { setTenant } from "../store/actions";
import { defined } from "../utils/variableEvaluation";

export function sideActions({ dispatch, getState }) {
  return (next) => (action) => {
    if (action.type === "@@router/LOCATION_CHANGE") {
      const tenantParam =
        getParameterByName("tenantName") || getParameterByName("tenantname");
      const tenantName = getState().globalSettingsState?.tenantName;
      // Set tenantName on any url change
      if (defined(tenantParam) && tenantParam !== tenantName) {
        console.log("middlewares sideActions tenantParam", tenantParam);
        dispatch(setTenant(tenantParam));
      }
    }
    return next(action);
  };
}
