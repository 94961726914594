/**
 * Renders the appropriate content depending if the predicate is true or false
 */

import { IDictionary } from "../models";
// import { replace } from 'connected-react-router';

export function renderIf(
  condition: boolean,
  content: {
    ifTrue: () => JSX.Element | JSX.Element[];
    ifFalse: () => JSX.Element | JSX.Element[];
  }
): JSX.Element | JSX.Element[] {
  if (condition) {
    return content.ifTrue();
  } else {
    return content.ifFalse();
  }
}

/**
 * Renders the given content only if the condition is true
 */
export function renderIfTrue(
  condition: boolean,
  content: () => JSX.Element
): JSX.Element | JSX.Element[] | null {
  if (condition) {
    return content();
  }
  return null;
}

/**
 * Renders the given content only if the condition is false
 */
export function renderIfFalse(
  condition: boolean,
  content: () => JSX.Element | JSX.Element[]
): JSX.Element | JSX.Element[] | null {
  if (!condition) {
    return content();
  }
  return null;
}

/**
 * Renders the appropriate content depending if the variable is defined or not
 * @param data      Data variable to check if is defined & not null
 * @param content   The content to render
 */
export function renderDefinedTrue<T>(
  data: T,
  content: (data: T) => JSX.Element
): JSX.Element | null {
  if (typeof data !== "undefined" && data !== null) {
    return content(data);
  } else {
    return null;
  }
}

/**
 * Renders the appropriate content depending if the variable is defined or not
 * @param data      Data variable to check if is defined & not null
 * @param content   The content to render
 */
export function renderDefined<T>(
  data: T,
  content: {
    isDefined: (data: T) => JSX.Element | JSX.Element[];
    isNotDefined: () => JSX.Element | JSX.Element[];
  }
): JSX.Element | JSX.Element[] {
  if (typeof data !== "undefined" && data !== null) {
    return content.isDefined && content.isDefined(data);
  } else {
    return content.isNotDefined && content.isNotDefined();
  }
}

/**
 * Renders a callback from the callbacks dictionary based on a key (similar to the switch statement)
 * @param key               Key of the callback to call
 * @param callbacks         All the available callbacks
 * @param defaultCallback   Available if nothing else matches
 */
export function renderSwitch(
  key: string | number,
  callbacks: IDictionary<() => JSX.Element>,
  defaultCallback?: () => JSX.Element
): JSX.Element | null {
  if (callbacks[key]) {
    return callbacks[key]();
  } else {
    if (defaultCallback) {
      return defaultCallback();
    } else {
      return null;
    }
  }
}

export function inRange(x, min, max) {
  return (x - min) * (x - max) <= 0;
}

// Month here is 1-indexed (January is 1, February is 2, etc). This is
// because we're using 0 as the day so that it returns the last day
// of the last month, so you have to add 1 to the month number
// so it returns the correct amount of days
export const daysInMonth = (month, year) => {
  return new Date(year, month, 0).getDate();
};

// ???????
export const parseDate = (strRaw, type?) => {
  if (strRaw) {
    let str = null;
    if (strRaw.length > 10) {
      str = strRaw;
    } else {
      str = strRaw ? strRaw.toString().replace(/\./g, "/") : null;
    }

    var options = {};

    if (type === "hr-min") {
      options = {
        hour: "2-digit",
        minute: "2-digit",
      };
      return new Date(Date.parse(str)).toLocaleTimeString("de-DE", options);
    } else if (type === "dateObject") {
      return new Date(str);
    } else if (type === "secsToDDMMYYYY") {
      options = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        // timeZone: "UTC"
      };
      return new Date(str).toLocaleDateString("de-DE", options);
    } else if (type === "toSecs") {
      return new Date(str).getTime();
    } else {
      options = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      };
      return new Date(Date.parse(str)).toLocaleDateString("de-DE", options);
    }
  } else return "";
};

// FROM - OR . STRING DATE TO DATETIME
export const parseStringDateToDate = (
  stringDate: string,
  setLastDay?: boolean
): Date => {
  const splittedDate = stringDate.split(/[-.]/);
  let day = Number(splittedDate[0]);

  if (setLastDay) {
    day = daysInMonth(Number(splittedDate[1]), Number(splittedDate[2]));
  }

  const dateObj = new Date(
    Number(splittedDate[2]),
    Number(Number(splittedDate[1]) - 1),
    day
  );
  return dateObj;
};

// FROM .NET DATE TO SWITZERLAND DATE
export const dateFormat = (value: string, type?: string) => {
  if (type === "yyyymmdd") {
    return (
      value &&
      value
        .split("T")[0]
        .replace(/[^0-9]/g, "")
        .replace(/(\w{4})(\w{2})(\w{2})/g, "$3.$2.$1")
    );
  } else if (type === "yyyyddmm") {
    return (
      value &&
      value
        .split("T")[0]
        .replace(/[^0-9]/g, "")
        .replace(/(\w{4})(\w{2})(\w{2})/g, "$2.$3.$1")
    );
  } else {
    const prepareDate = value.includes("T") ? value.split("T")[0] : value;
    return (
      prepareDate &&
      prepareDate
        .replace(/[^0-9]/g, "")
        .replace(/(\w{2})(\w{2})(\w{4})/g, "$1.$2.$3")
    );
  }
};

// FROM . OR - STRING DATE TO API DATE
export const parseToApiDate = (date, inputFormat) => {
  if (date === null) {
    return date;
  }

  if (inputFormat === "mmddyyyy") {
    if (
      /\d{2}\.\d{2}\.\d{4}/gm.test(date) ||
      /\d{2}-\d{2}-\d{4}/gm.test(date)
    ) {
      const parts = date.split(/[-.]/);
      return parts[2] + "-" + parts[0] + "-" + parts[1] + "T12:00:00.000Z";
    }
  } else if (inputFormat === "ddmmyyyy") {
    if (
      /\d{2}\.\d{2}\.\d{4}/gm.test(date) ||
      /\d{2}-\d{2}-\d{4}/gm.test(date)
    ) {
      const parts = date.split(/[-.]/);
      return parts[2] + "-" + parts[1] + "-" + parts[0] + "T12:00:00.000Z";
    }
  } else if (inputFormat === "yyyyddmm") {
    if (
      /\d{2}\.\d{2}\.\d{4}/gm.test(date) ||
      /\d{2}-\d{2}-\d{4}/gm.test(date)
    ) {
      const parts = date.split(/[-.]/);
      return parts[2] + "-" + parts[0] + "-" + parts[1] + "T12:00:00.000Z";
    }
  }
  return date;
};

export const addYears = (yyyy_mm_dd, years) => {
  const dateSegments = yyyy_mm_dd.split("-");
  dateSegments[0] = parseInt(dateSegments[0]) + years;
  return dateSegments.join("-");
};

export const getMonth = (date, correctingValue?) => {
  return new Date(date).getMonth() + (correctingValue || 0);
};

export const getYear = (date) => {
  return new Date(date).getFullYear();
};

export const getDate = (date) => {
  return new Date(date).getDate();
};

export const monthDiff = (d1, d2) => {
  var months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
};

export const isEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};

export function debounce(func, delay) {
  var toId: any = -1;

  return () => {
    if (toId >= 0) {
      clearTimeout(toId);
    }
    toId = setTimeout(func, delay);
  };
}

export function formatNumber(num, trailingDigits?) {
  if (!num) {
    return num;
  }
  if (typeof num === "string" && /[a-zA-Z]/.test(num)) {
    return num;
  }
  if (typeof num === "string") {
    num = num.replace(/'/g, "").replace(/,/g, ".");
  }

  return parseFloat(num)
    .toFixed(trailingDigits || 0)
    .replace(/./g, function (c, i, a) {
      return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "'" + c : c;
    });
}

export function formatMoney(
  amount: any,
  decimalCount = 2,
  decimal = ".",
  thousands = "'"
) {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    // deal with decimals
    const splitted = amount.toString().split(".");

    amount = splitted[0];

    let i = parseFloat(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (splitted[1]
        ? `.${splitted[1]}${splitted[1]?.length === 1 ? "0" : ""}`
        : "")
    );
  } catch (e) {
    console.log(e);
  }
}

export function formatSliderValue(val, maxLength?) {
  let value = val;
  if (value && Array.from(value).length > 1) {
    value = value.replace(/[^\d.-]/g, "");
    if (Array.from(value).length > maxLength) {
      value = value.slice(0, maxLength - Array.from(value).length);
    }
    if (Array.from(value)[0] === "0") {
      value = value.substring(1);
    }
  }
  return value;
}

(window as any).findReactComponent = function (el) {
  for (const key in el) {
    if (key.startsWith("__reactInternalInstance$")) {
      const fiberNode = el[key];

      return fiberNode && fiberNode.return && fiberNode.return.stateNode;
    }
  }
  return null;
};

export function isDate(value): boolean {
  // First check for the pattern
  if (
    !(
      /^\d{1,2}\.\d{1,2}\.\d{4}$/.test(value) ||
      /^\d{4}-\d{1,2}-\d{1,2}/.test(value)
    )
  )
    return false;

  // Parse the date parts to integers
  var parts = value.split(".");
  var day = parseInt(parts[0], 10);
  var month = parseInt(parts[1], 10);
  var year = parseInt(parts[2], 10);

  if (parts.length < 3) {
    parts = value.split("-");
    day = parseInt(parts[2], 10);
    month = parseInt(parts[1], 10);
    year = parseInt(parts[0], 10);
  }

  // Check the ranges of month and year
  if (year < 1000 || year > 3000 || month === 0 || month > 12) return false;

  var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Adjust for leap years
  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0))
    monthLength[1] = 29;

  // Check the range of the day

  return day > 0 && day <= monthLength[month - 1];
}

export function toggleFullScreen(val, ev?) {
  var doc = window.document;
  var docEl = (doc as any).documentElement;

  var requestFullScreen =
    (docEl as any).requestFullscreen ||
    (docEl as any).mozRequestFullScreen ||
    (docEl as any).webkitRequestFullScreen ||
    (docEl as any).msRequestFullscreen;
  var cancelFullScreen =
    (doc as any).exitFullscreen ||
    (doc as any).mozCancelFullScreen ||
    (doc as any).webkitExitFullscreen ||
    (doc as any).msExitFullscreen;

  if (
    !(doc as any).fullscreenElement &&
    !(doc as any).mozFullScreenElement &&
    !(doc as any).webkitFullscreenElement &&
    !(doc as any).msFullscreenElement
  ) {
    requestFullScreen && requestFullScreen.call(docEl);
  } else {
    if (val && val === false) {
      cancelFullScreen && cancelFullScreen.call(doc);
    }
  }
}

export const flattenMessages = (nestedMessages, prefix = "") => {
  if (nestedMessages === null) {
    return {};
  }
  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === "string") {
      Object.assign(messages, { [prefixedKey]: value });
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});
};

export function inputFormatDate(val: any) {
  if (val && Array.from(val).length > 0) {
    val = val.replace(/[^\d.-]/g, "");

    if (val && val.length && val.length > 10) {
      val = val.substr(0, 10);
    }

    if (val === "") return val;
    var value = val;

    if (!value) return val;
    var parts = val.split(".");
    if (parts.length === 1) {
      if (val.length > 2) {
        value = val[0] + val[1] + "." + val[2];
      }
    }
    if (parts.length === 2) {
      if (parts[0].length === 1 && parts[1].length === 0) {
        value = "0" + parts[0] + ".";
      }
      if (parts[1].length === 3) {
        value = parts[0] + "." + parts[1][0] + parts[1][1] + "." + parts[1][2];
      }
    }
    if (parts.length === 3) {
      if (parts[1].length === 1 && parts[2].length === 0) {
        value = parts[0] + ".0" + parts[1] + "." + parts[2];
      }
      if (parts[1].length === 2) {
        value = parts[0] + "." + parts[1][0] + parts[1][1] + "." + parts[2];
      }
    }

    return value;
  } else {
    return val;
  }
}

export function getAge(birthDate, diffDate) {
  let refDate = birthDate;
  let offset = getMonth(birthDate);
  let age = new Date(diffDate).getFullYear() - new Date(refDate).getFullYear();
  if (new Date(diffDate).getMonth() < offset) {
    return age - 1;
  } else {
    return age;
  }
}
