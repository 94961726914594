import i18n, { TFunction } from "i18next";
import Backend from "i18next-locize-backend";
import locizeLastUsed from "locize-lastused";

import { AnyAction, Store } from "redux";
import { RootState } from "../store/reducers";
import { getLanguageFromUrl } from "../constants/routes";

type Callback = (error: any, t: TFunction) => void;
const getReduxState: () => Store<RootState, AnyAction> = () =>
  require("../store").default;

const store: Store<RootState, AnyAction> = getReduxState();

const i18nextOptions: Partial<Callback> = {
  load: "currentOnly",
  initImmediate: false,
  preload: ["de-CH"],
  debug: true,
  fallbackLng: "de-CH",
  saveMissing: true,
  interpolation: {
    escapeValue: false,
  },
  ns: ["common", "app", "pageChart"],
  supportedLngs: ["de-CH", "de-DE", "en-US", "fr-FR", "it-IT", "pt-BR"],
};
i18n.use(Backend).use(locizeLastUsed);

function init() {
  if (
    store.getState().globalSettingsState.locizeSettings &&
    !i18n.isInitialized
  ) {
    const globalSettingsState = store.getState().globalSettingsState;
    const { locizeSettings } = globalSettingsState;

    const defaultSettings = locizeSettings;

    const { apiKey, projectId, version } = defaultSettings;

    const languageFromUrl = getLanguageFromUrl();

    if (
      !(i18nextOptions as any).supportedLngs?.find(
        (lng) => lng === languageFromUrl
      )
    ) {
      window.open(
        window.location.href.replace(languageFromUrl, "de-CH"),
        "_self"
      );
    }

    const language =
      (i18nextOptions as any).supportedLngs?.find(
        (lng) => lng === languageFromUrl
      ) ?? "de-CH";

    const locizeOptions = {
      projectId,
      apiKey,
      referenceLng: language,
      allowedAddOrUpdateHosts: ["localhost"],
      version,
    };

    (i18nextOptions as any).preload = [language];
    (i18nextOptions as any).fallbackLng = language;

    i18n.init({
      ...i18nextOptions,
      backend: locizeOptions,
      locizeLastUsed: {
        projectId,
      },
    });
  }
}

store?.subscribe(init);
export default i18n;
