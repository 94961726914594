import { lazy, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  RootState,
  checkIfTenantIsValid,
  setI18NLoading,
  setLanguageItemsList,
} from "./store";
import { i18n } from "./locale";
import {
  DefaultRouteNames,
  RouteURLsByLanguage,
  getLanguageFromUrl,
  getRouteByValue,
  getTenantFromUrl,
} from "./constants/routes";
import { getParameterByName } from "./utils/toSearchParams";
import { useHistory } from "react-router-dom";

const RoutesComponent = lazy(() => import("./Routes"));

const App = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    globalSettingsState: { locizeSettings },
  } = useSelector((state: RootState) => state);
  const langParam = getLanguageFromUrl();
  const langCode = i18n.language;

  useEffect(() => {
    if (
      i18n.isInitialized &&
      langParam &&
      i18n.language !== langParam &&
      langParam !== langCode
    ) {
      console.clear();
      console.log("USER FROM INSURANCE ----------->", langParam);
      i18n.changeLanguage(langParam);
    }
  }, [langParam, i18n.isInitialized, langCode]);

  useEffect(() => {
    if (!locizeSettings) {
      dispatch(setI18NLoading(true));
    }
  }, [locizeSettings]);

  useEffect(() => {
    i18n.on("languageChanged", () => {
      dispatch(setI18NLoading(false));
    });
    i18n.on("initialized", async () => {
      const data = await i18n.services.backendConnector.backend.getLanguages();
      dispatch(setLanguageItemsList(data));
      dispatch(setI18NLoading(false));
    });
  }, [i18n.language, i18n.isInitialized]);

  const handleMissingParams = (
    segments: string[],
    tenant: string,
    language: string,
    route: string
  ) => {
    if (
      (!segments?.includes(tenant.toLowerCase()) &&
        !segments?.includes(tenant.toUpperCase())) ||
      !segments?.includes(language) ||
      segments.length !== 4
    ) {
      history.push({
        pathname: `/${tenant}/${language}/${route.substring(1)}`,
      });
    }
  };

  const updateUrlIfOutdated = (routeURLs: string) => {
    const oldLanguageQueryString = getParameterByName("language");
    const oldTenantQueryString = getParameterByName("tenantName");

    if (oldLanguageQueryString && oldTenantQueryString) {
      history.push({
        pathname: `/${oldTenantQueryString}/${oldLanguageQueryString}/${routeURLs}`,
      });
    } else if (!oldLanguageQueryString && oldTenantQueryString) {
      history.push({
        pathname: `/${oldTenantQueryString}/de-CH/${routeURLs}`,
      });
    } else if (oldLanguageQueryString && !oldTenantQueryString) {
      history.push({
        pathname: `/BVG/${oldLanguageQueryString}/${routeURLs}`,
      });
    }
  };

  const openOnDefaultTenant = () => {
    window.open(`${window.location.origin}/PKSO/de-CH/welcomedesktop`, "_self");
  };

  useEffect(() => {
    (async () => {
      const url = window.location.pathname.split("?")[0]; // Remove '#'
      const segments = url.split("/");

      // validate if url contains just the tenant
      if (segments.length < 4) {
        const oldTenantQueryString = getParameterByName("tenantName");
        const oldLanguageQueryString = getParameterByName("language");

        const currentTenant =
          oldTenantQueryString ?? segments[1].replace("/", "");

        const currentLanguage =
          oldLanguageQueryString ?? segments[2]?.replace("/", "");

        if (
          !currentTenant ||
          !(await checkIfTenantIsValid(currentTenant))?.data
        ) {
          openOnDefaultTenant();
        } else {
          const tenantPath = `${window.location.origin}/${currentTenant}/${
            currentLanguage || "de-CH"
          }/welcomedesktop`;
          window.open(tenantPath, "_self");
        }
      } else {
        const tenant = getTenantFromUrl();
        const language = getLanguageFromUrl();
        const currentRoute = `/${segments[3]}`;

        const routeURLs =
          RouteURLsByLanguage[
            `${DefaultRouteNames[getRouteByValue(currentRoute)]}-${language}`
          ] || `/${DefaultRouteNames.welcomedesktop}`;

        updateUrlIfOutdated(routeURLs);
        handleMissingParams(segments, tenant, language, routeURLs);

        if (
          i18n.language &&
          language &&
          (i18n.language !== language || routeURLs !== currentRoute)
        ) {
          window.open(
            window.location.href
              .replace(currentRoute, routeURLs)
              .replace(i18n.language, language),
            "_self"
          );
        }
      }
    })();
  }, [i18n.language, window.location.href]);

  return <RoutesComponent />;
};

export default App;
