import { ICRPensionCalculatorBaseIRetirement } from "pensiondynamics.graph.engine/models";
import * as constants from "../../constants";
import { parseStringDateToDate } from "../../utils/react";

export interface TeilPensionState {
  isTeilPensionUntouched: boolean;
  isTeilPensionFinished: boolean;
  newBuildedRetirementStep: ICRPensionCalculatorBaseIRetirement;
}

const getInitialState = () => {
  const initialState: TeilPensionState = {
    isTeilPensionUntouched: true,
    isTeilPensionFinished: false,
    newBuildedRetirementStep: {
      activityRateAfterRetirement: 0,
      bridgingPensionAmount: 0,
      capitalPurchaseAmount: 0,
      capitalPurchaseDate: new Date(Date.UTC(1, 1, 1)),
      capitalWidthdrawalAmount: 0,
      retirementDate: null,
      retirementPercentage: 0,
      retirementStepId: null,
      grossSalaryAfterRetirement: 0,
    },
  };

  return initialState;
};

const teilPensionState = (
  state: TeilPensionState = getInitialState(),
  action: any
) => {
  switch (action.type) {
    case constants.SET_TEILPENSION_DATE: {
      return {
        ...state,
        isTeilPensionUntouched: false,
        newBuildedRetirementStep: {
          ...state.newBuildedRetirementStep,
          retirementDate: parseStringDateToDate(action.payload),
        },
      };
    }

    case constants.SET_TEILPENSION_GROSS_SALARY: {
      return {
        ...state,
        newBuildedRetirementStep: {
          ...state.newBuildedRetirementStep,
          grossSalaryAfterRetirement: action.payload,
        },
      };
    }

    case constants.SET_TEILPENSION_RETIREMENT_PERCENTAGE: {
      return {
        ...state,
        newBuildedRetirementStep: {
          ...state.newBuildedRetirementStep,
          retirementPercentage: action.payload,
        },
      };
    }

    case constants.SET_TEILPENSION_ACTIVITY_RATE: {
      return {
        ...state,
        newBuildedRetirementStep: {
          ...state.newBuildedRetirementStep,
          activityRateAfterRetirement: action.payload,
        },
      };
    }

    case constants.SET_TEILPENSION_FINISHED: {
      return {
        ...state,
        isTeilPensionFinished: !state.isTeilPensionFinished,
      };
    }

    case constants.RESET_TEILPENSION: {
      return getInitialState();
    }
  }
  return state;
};

export default teilPensionState;
