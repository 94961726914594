import { ThemeProvider } from "@emotion/react";
import { BrowserRouter as Router } from "react-router-dom";
import { GlobalStyles, theme } from "icr-shared-components";
import "icr-shared-components/dist/index.css";
import { Suspense } from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import App from "./App";
import Spinner from "./components/Spinner";
import i18n from "./locale/i18n";
import * as serviceWorker from "./serviceWorker";
import { store } from "./store";
import "./styles/index.scss";

ReactDOM.render(
  <Suspense fallback={<Spinner />}>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Router>
            <GlobalStyles />
            <ReduxToastr
              timeOut={4000}
              newestOnTop={false}
              preventDuplicates
              position="top-center"
              transitionIn="fadeIn"
              transitionOut="fadeOut"
              closeOnToastrClick
            />
            <App />
          </Router>
        </ThemeProvider>
      </Provider>
    </I18nextProvider>
  </Suspense>,
  document.getElementById("root") as HTMLElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
