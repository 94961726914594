import { routerMiddleware } from "connected-react-router";
import { createMemoryHistory } from "history";
import {
  applyMiddleware,
  legacy_createStore as createStore,
  Store,
} from "redux";
import thunk from "redux-thunk";
import { sideActions } from "../middlewares";
import createRootReducer, { RootState } from "./reducers";
export * from "./actions";
export * from "./reducers";

export const historyS = createMemoryHistory();

const middleware = applyMiddleware(
  routerMiddleware(historyS), // for dispatching history actions
  sideActions,
  thunk
);

export const store = createStore(
  createRootReducer(historyS) as any,
  middleware as any
) as Store<RootState>;

export default store;
