import { GraphBase } from "pensiondynamics.graph.engine/src/GraphBase/GraphBase";
import { IGraphBaseProps } from "pensiondynamics.graph.engine/src/GraphBase/IGraphBaseProps";
import { BlockSizeStrategy } from "pensiondynamics.graph.engine/src/GraphBase/GraphColumn/Block/BlockSizeStrategy";
import * as constants from "../../constants";
import { EventCard } from "../../containers/Visualisation/ChartContainer/ChartControls/ChartCards";

export interface GraphEngineState {
  graphData: GraphBase;
  ahvPensionFromStandalone: number;
  payrollClassFromStandalone: number;
  beforePensionCardsList: EventCard[];
  afterPensionCardsList: EventCard[];
}

const getInitialState = () => {
  const initialState: GraphEngineState = {
    graphData: null,
    ahvPensionFromStandalone: null,
    payrollClassFromStandalone: 30,
    beforePensionCardsList: [],
    afterPensionCardsList: [],
  };

  return initialState;
};

const graphEngineState = (
  state: GraphEngineState = getInitialState(),
  action: any
) => {
  switch (action.type) {
    case constants.CALCULATE_GRAPH_DATA: {
      state.graphData.calculate(action.payload.data);
      return state;
    }

    case constants.INITIATE_GRAPH_DATA: {
      let graphConfiguration: IGraphBaseProps = {
        graphConfiguration: {
          canvas: action.payload.canvas,
          SpaceWidth: 10,
          ZoomFactor: 1,
          ColumnMinWidth: 136,
          ZoomWidthInMonths: 12,
          minMonthWidth: 1,
          minWidth: 912,
          minBlockHeight: 8,
          blockSizeStrategy:
            BlockSizeStrategy.EnlargeTooSmallBlocksAndPreserveMaxColumnHeight,
        },
        ahvPension: state.ahvPensionFromStandalone * 12,
        payrollClass: state.payrollClassFromStandalone,
        person: action.payload.person,
        tenantConfiguration: action.payload.tenantConfiguration,
        localStorage: sessionStorage,
        currentDate: action.payload.keyDate,
      };

      let graphBaseConfig: GraphBase;
      graphBaseConfig = new GraphBase(graphConfiguration);

      return {
        ...state,
        graphData: graphBaseConfig,
      };
    }

    case constants.SET_AHV_PENSION_FROM_STANDALONE: {
      return {
        ...state,
        ahvPensionFromStandalone: action.payload,
      };
    }

    case constants.SET_PAYROLL_CLASS_FROM_STANDALONE: {
      return {
        ...state,
        payrollClassFromStandalone: Number(action.payload),
      };
    }

    case constants.SET_BEFORE_PENSION_CARDS_LIST: {
      return {
        ...state,
        beforePensionCardsList: action.payload,
      };
    }

    case constants.SET_AFTER_PENSION_CARDS_LIST: {
      return {
        ...state,
        afterPensionCardsList: action.payload,
      };
    }

    case constants.CLEAR_CONTROLS: {
      const modalContent = action.payload;
      let afterUpdated = state.afterPensionCardsList;
      let beforeUpdated = state.beforePensionCardsList;

      if (modalContent === "capitalwithdrawal") {
        state.graphData.cards.capitalWithdrawalCard.reset();
        afterUpdated = afterUpdated.filter(
          (item) => item.id !== "capitalWithdrawals"
        );
      }

      if (modalContent === "überbrückungsrente") {
        state.graphData.cards.bridgingPensionCard.reset();
        if (
          !state.graphData.cards.bridgingPensionCard.isBridgingPensionPossible
        ) {
          afterUpdated = afterUpdated.filter(
            (item) => item.id !== "sliderValueBridgingPension"
          );
        }
      }

      if (
        modalContent === "startCapitalBuyIn" ||
        modalContent === "showallwithdrawals" ||
        modalContent === "capitalBuyIn" ||
        modalContent === "capitalbuyinedit" ||
        modalContent === "showAllBuyIns"
      ) {
        state.graphData.cards.purchaseCard.reset();
        beforeUpdated = beforeUpdated.filter(
          (item) => item.id !== "sliderValuesPurchase"
        );
      }

      if (modalContent === "scheidung") {
        state.graphData.cards.divorceCard.reset();
        beforeUpdated = beforeUpdated.filter(
          (item) => item.id !== "divorcePostings"
        );
      }

      if (modalContent === "homeownership") {
        state.graphData.cards.homeOwnershipCard.reset();
        beforeUpdated = beforeUpdated.filter(
          (item) => item.id !== "homeownershipPostings"
        );
      }

      if (modalContent === "additionalSavings") {
        state.graphData.cards.planCard.reset();
        beforeUpdated = beforeUpdated.filter(
          (item) => item.id !== "additionalSavingsPlanSelected"
        );
      }

      if (modalContent === "ahvRente") {
        state.graphData.cards.ahvPensionCard.reset();
        beforeUpdated = beforeUpdated.filter((item) => item.id !== "ahvRente");
      }

      return {
        ...state,
        beforePensionCardsList: beforeUpdated,
        afterPensionCardsList: afterUpdated,
      };
    }

    case constants.RESET_GRAPH_DATE: {
      state.graphData.reset();
      return state;
    }
  }

  return state;
};

export default graphEngineState;
