import { getParameterByName } from "../utils/toSearchParams";

export enum DefaultRouteNames {
  "/" = "welcomedesktop",
  welcomedesktop = "welcomedesktop",
  anliegen = "anliegen",
  detaillierung = "detaillierung",
  geburtsdatum = "geburtsdatum",
  geschlecht = "geschlecht",
  wohnort = "wohnort",
  ausgestelltam = "ausgestelltam",
  lohnsumme = "lohnsumme",
  lohnklasse = "lohnklasse",
  beschaftigungsgrad = "beschaftigungsgrad",
  sparguthaben = "sparguthaben",
  plan = "plan",
  vpkonto = "vpkonto",
  fragen = "fragen",
  zeitpunkt = "zeitpunkt",
  bruttolohn = "bruttolohn",
  rentenanteil = "rentenanteil",
  arbeitspensum = "arbeitspensum",
  fragenbean = "fragenbean",
  chart = "chart",
}

export enum RouteURLsByLanguage {
  "/-en-US" = "/welcomedesktop",
  "welcomedesktop-en-US" = "/welcomedesktop",
  "anliegen-en-US" = "/anliegen",
  "detaillierung-en-US" = "/detaillierung",
  "geburtsdatum-en-US" = "/geburtsdatum",
  "geschlecht-en-US" = "/geschlecht",
  "wohnort-en-US" = "/wohnort",
  "ausgestelltam-en-US" = "/ausgestelltam",
  "lohnsumme-en-US" = "/lohnsumme",
  "lohnklasse-en-US" = "/lohnklasse",
  "beschaftigungsgrad-en-US" = "/beschaftigungsgrad",
  "sparguthaben-en-US" = "/sparguthaben",
  "plan-en-US" = "/plan",
  "vpkonto-en-US" = "/vpkonto",
  "fragen-en-US" = "/fragen",
  "zeitpunkt-en-US" = "/zeitpunkt",
  "bruttolohn-en-US" = "/bruttolohn",
  "rentenanteil-en-US" = "/rentenanteil",
  "arbeitspensum-en-US" = "/arbeitspensum",
  "fragenbean-en-US" = "/fragenbean",
  "chart-en-US" = "/chart",

  "/-de-CH" = "/welcomedesktop",
  "welcomedesktop-de-CH" = "/welcomedesktop",
  "anliegen-de-CH" = "/anliegen",
  "detaillierung-de-CH" = "/detaillierung",
  "geburtsdatum-de-CH" = "/geburtsdatum",
  "geschlecht-de-CH" = "/geschlecht",
  "wohnort-de-CH" = "/wohnort",
  "ausgestelltam-de-CH" = "/ausgestelltam",
  "lohnsumme-de-CH" = "/lohnsumme",
  "lohnklasse-de-CH" = "/lohnklasse",
  "beschaftigungsgrad-de-CH" = "/beschaftigungsgrad",
  "sparguthaben-de-CH" = "/sparguthaben",
  "plan-de-CH" = "/plan",
  "vpkonto-de-CH" = "/vpkonto",
  "fragen-de-CH" = "/fragen",
  "zeitpunkt-de-CH" = "/zeitpunkt",
  "bruttolohn-de-CH" = "/bruttolohn",
  "rentenanteil-de-CH" = "/rentenanteil",
  "arbeitspensum-de-CH" = "/arbeitspensum",
  "fragenbean-de-CH" = "/fragenbean",
  "chart-de-CH" = "/chart",

  "/-fr-FR" = "/welcomedesktop",
  "welcomedesktop-fr-FR" = "/welcomedesktop",
  "anliegen-fr-FR" = "/anliegen",
  "detaillierung-fr-FR" = "/detaillierung",
  "geburtsdatum-fr-FR" = "/geburtsdatum",
  "geschlecht-fr-FR" = "/geschlecht",
  "wohnort-fr-FR" = "/wohnort",
  "ausgestelltam-fr-FR" = "/ausgestelltam",
  "lohnsumme-fr-FR" = "/lohnsumme",
  "lohnklasse-fr-FR" = "/lohnklasse",
  "beschaftigungsgrad-fr-FR" = "/beschaftigungsgrad",
  "sparguthaben-fr-FR" = "/sparguthaben",
  "plan-fr-FR" = "/plan",
  "vpkonto-fr-FR" = "/vpkonto",
  "fragen-fr-FR" = "/fragen",
  "zeitpunkt-fr-FR" = "/zeitpunkt",
  "bruttolohn-fr-FR" = "/bruttolohn",
  "rentenanteil-fr-FR" = "/rentenanteil",
  "arbeitspensum-fr-FR" = "/arbeitspensum",
  "fragenbean-fr-FR" = "/fragenbean",
  "chart-fr-FR" = "/chart",
}

export const getLanguageFromUrl = () => {
  const url = window.location.pathname;
  const segments = url.split("/");
  const language = segments[2];
  const oldLanguageQueryString = getParameterByName("language");

  if (oldLanguageQueryString) return oldLanguageQueryString;

  if (segments.length < 4 || !language) return "de-CH";

  return language;
};

export const getRouteByValue = (value: string): string => {
  return Object.keys(RouteURLsByLanguage)
    ?.find((key) => RouteURLsByLanguage[key] === value)
    ?.split("-")[0];
};

export const getTenantFromUrl = () => {
  const url = window.location.pathname;
  const segments = url.split("/");
  const urlTenant = segments[1];
  const localStorageTenant = window.localStorage.getItem("tenantName");
  const oldTenantQueryString = getParameterByName("tenantName");

  if (oldTenantQueryString) return oldTenantQueryString;

  if (segments.length < 3 || !urlTenant) return "PKSO";

  if (!urlTenant && localStorageTenant) return localStorageTenant;

  return urlTenant;
};

export const getRouteFromUrl = () => {
  const url = window.location.pathname.includes("portalUrl")
    ? window.location.pathname.slice(1).split("?")[0]
    : window.location.pathname.slice(1);
  const segments = url.split("/");
  const routeWithoutParams = segments[segments.length - 1].split("?")[0];
  const currentRoute = `/${routeWithoutParams}`;

  return currentRoute;
};

export const getRouteByLanguage = (routeName: string): string => {
  const language = getParameterByName("language") || getLanguageFromUrl();

  return `${getTenantFromUrl()}/${language}${
    RouteURLsByLanguage[`${routeName}-${getLanguageFromUrl()}`]
  }`;
};

export const routes = [
  {
    showInMenu: false,
    key: "welcomedesktop",
    path: getRouteByLanguage(DefaultRouteNames.welcomedesktop),
    id: "welcomedesktop",
    title: "Welcome | Site",
    step: 4,
    description:
      "If you are using react-helmet, you can read this description.",
    exact: true,
  },
  {
    showInMenu: true,
    key: "anliegen",
    path: getRouteByLanguage(DefaultRouteNames.anliegen),
    id: "anliegen",
    exact: true,
    step: 4,
    navigationStep: 1,
  },
  {
    showInMenu: true,
    key: "detaillierung",
    path: getRouteByLanguage(DefaultRouteNames.detaillierung),
    id: "detaillierung",
    exact: true,
    step: 4,
    navigationStep: 1,
  },
  {
    showInMenu: true,
    key: "geburtsdatum",
    path: getRouteByLanguage(DefaultRouteNames.geburtsdatum),
    id: "geburtsdatum",
    exact: true,
    step: 4,
    navigationStep: 2,
  },
  {
    showInMenu: true,
    key: "geschlecht",
    path: getRouteByLanguage(DefaultRouteNames.geschlecht),
    id: "geschlecht",
    exact: true,
    step: 5,
    navigationStep: 2,
  },
  {
    showInMenu: true,
    key: "wohnort",
    path: getRouteByLanguage(DefaultRouteNames.wohnort),
    id: "wohnort",
    exact: true,
    step: 6,
    navigationStep: 2,
  },
  {
    showInMenu: true,
    key: "ausgestelltam",
    path: getRouteByLanguage(DefaultRouteNames.ausgestelltam),
    id: "ausgestelltam",
    exact: true,
    step: 7,
    navigationStep: 3,
  },
  {
    showInMenu: true,
    key: "lohnsumme",
    path: getRouteByLanguage(DefaultRouteNames.lohnsumme),
    id: "lohnsumme",
    exact: true,
    step: 8,
    navigationStep: 3,
  },
  {
    showInMenu: true,
    key: "lohnklasse",
    path: getRouteByLanguage(DefaultRouteNames.lohnklasse),
    id: "lohnklasse",
    exact: true,
    step: 9,
    navigationStep: 3,
  },
  {
    showInMenu: true,
    key: "beschaftigungsgrad",
    path: getRouteByLanguage(DefaultRouteNames.beschaftigungsgrad),
    id: "beschaftigungsgrad",
    exact: true,
    step: 9,
    navigationStep: 3,
  },
  {
    showInMenu: true,
    key: "sparguthaben",
    path: getRouteByLanguage(DefaultRouteNames.sparguthaben),
    id: "sparguthaben",
    exact: true,
    step: 10,
    navigationStep: 3,
  },
  {
    showInMenu: true,
    key: "plan",
    path: getRouteByLanguage(DefaultRouteNames.plan),
    id: "plan",
    exact: true,
    step: 10,
    navigationStep: 3,
  },
  {
    showInMenu: true,
    key: "vpkonto",
    path: getRouteByLanguage(DefaultRouteNames.vpkonto),
    id: "vpkonto",
    exact: true,
    step: 11,
    navigationStep: 3,
  },
  {
    showInMenu: true,
    key: "fragen",
    path: getRouteByLanguage(DefaultRouteNames.fragen),
    id: "fragen",
    exact: true,
    step: 13,
    navigationStep: null,
  },
  {
    showInMenu: true,
    key: "zeitpunkt",
    path: getRouteByLanguage(DefaultRouteNames.zeitpunkt),
    id: "zeitpunkt",
    exact: true,
    step: 14,
    navigationStep: 4,
  },
  {
    showInMenu: true,
    key: "bruttolohn",
    path: getRouteByLanguage(DefaultRouteNames.bruttolohn),
    id: "bruttolohn",
    exact: true,
    step: 14,
    navigationStep: 4,
  },
  {
    showInMenu: true,
    key: "rentenanteil",
    path: getRouteByLanguage(DefaultRouteNames.rentenanteil),
    id: "rentenanteil",
    exact: true,
    step: 15,
    navigationStep: 4,
  },
  {
    showInMenu: true,
    key: "arbeitspensum",
    path: getRouteByLanguage(DefaultRouteNames.arbeitspensum),
    id: "arbeitspensum",
    exact: true,
    step: 16,
    navigationStep: 4,
  },
  {
    showInMenu: true,
    key: "fragenbean",
    path: getRouteByLanguage(DefaultRouteNames.fragenbean),
    id: "fragenbean",
    exact: true,
    step: 15,
    navigationStep: 4,
  },
  {
    showInMenu: true,
    key: "chart",
    path: getRouteByLanguage(DefaultRouteNames.chart),
    id: "chart",
    exact: true,
  },
];
